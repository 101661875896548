import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { FC } from 'react';
import ReactModal from 'react-modal';
import tw from 'twin.macro';

type PoemModalProps = {
  //
  modalOpen: boolean;
  setModalOpen: (boolean) => void;
  poemImage: IGatsbyImageData | undefined;
  case_name: string;
};

export const PoemModal: FC<PoemModalProps> = ({
  modalOpen,
  setModalOpen,
  poemImage,
  ...props
}) => {
  const onClose = () => {
    setModalOpen(false);
  };
  return (
    <ReactModal
      isOpen={modalOpen}
      onRequestClose={onClose}
      contentLabel="Media modal"
      overlayClassName="modal--overlay"
      tw="max-h-[75vh]"
      style={{
        overlay: {
          display: 'flex',
          justifyContent: 'center',
          position: 'fixed',
          inset: 0,
          zIndex: 100,
          backgroundColor: 'rgba(0,0,0,.6)',
          overflowY: 'auto',
        },
      }}
    >
      <Container>
        <ModalBody>
          <Text>{props.case_name}</Text>
          <GatsbyImage
            image={poemImage!} // modal isn't openable if !image
            // Prevent image saving, dragging, etc
            onDragStart={(e) => {
              e.preventDefault();
            }}
            onContextMenu={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            draggable={false}
            alt={`Artwork for ${props.case_name}`}
          />
          <Flex>
            <Button onClick={onClose}>Close</Button>
          </Flex>
        </ModalBody>
      </Container>
    </ReactModal>
  );
};
const Container = tw.div`p-3 max-w-5xl overflow-y-auto`;
const ModalBody = tw.div`bg-black p-4 [border: 1px solid rgb(var(--brand-coral))]`;
const Text = tw.h3`font-secondary text-white leading-6 md:leading-8 text-center font-bold pb-2 [border-bottom: 1px solid rgb(var(--brand-coral))]`;
const Flex = tw.div`flex w-full justify-end`;
const Button = tw.button`text-white font-semibold bg-brand-coral leading-none p-3 my-0 [border: 1px solid rgb(var(--brand-coral))] hover:(bg-black text-white) [transition:all .3s ease-in-out]`;
