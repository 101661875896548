import { AnimatePresence, motion, MotionProps, Variants } from 'framer-motion';
import { PageProps } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import parser from 'html-react-parser';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';
import tw from 'twin.macro';
import { LinkPlus } from '~/components/common';
import { Header } from '~/components/common/Header';
import {
  getPageExitClick,
  usePageExit,
} from '~/components/common/transitions/page-exit';
import { PoemInfo, PoemInfoPage } from '~/components/poem-info';
import { PoemModal } from '~/components/PoemModal';
import { PoemPageTopHalf } from '~/components/PoemPageTopHalf';
import { SEO } from '~/components/SEO';
import {
  AUDIO_VOLUME,
  Frontmatter,
  LocalStorageKey,
  Poem as PoemProps,
} from '~/config';
import { navData } from '~/config/nav';
import { AudioContext } from '~/context/AudioContext';
import { useHasMounted } from '~/hooks';
import { getStoredSessionBoolean, markdownToHtml } from '~/utils';

// don't add export - uses local gatsby plugin
// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Poem',
  order: 1,
  screenShoot: false,
};

type PoemPageProps = PageProps<object, PoemProps>;

const Page: FC<PoemPageProps> = ({ pageContext, path }) => {
  const hasMounted = useHasMounted();
  const [isEntered, setIsEntered] = useState(false);
  const { isExitingPage } = usePageExit(true);
  const [isHeaderInView, setHeaderInView] = useState(true);
  const [isHeaderInViewReady, setHeaderInViewReady] = useState(false);
  const handleHeaderInViewChange = useCallback(
    (isHeaderInViewReady: boolean, isHeaderInView: boolean) => {
      setHeaderInViewReady(isHeaderInViewReady);
      setHeaderInView(isHeaderInView);
    },
    [],
  );
  const { ref: lastElementRef, inView: showFooter } = useInView();

  const [mobileOpen, setMobileOpen] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const { isPlaying, play, stop } = useContext(AudioContext);
  // autoplay on landing unless user has muted audio in this session
  // this was moved here because there are two players on the mobile page,
  //
  useEffect(
    () => {
      const src = pageContext.audio_file?.publicURL;
      if (
        src &&
        !isPlaying &&
        !getStoredSessionBoolean(LocalStorageKey.audioMuted) //  set in AudioPlayer by user action
      ) {
        play(src, AUDIO_VOLUME);
      }
      return stop; // stop audio on leave page
    },
    // once on page load only
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const poemText =
    pageContext.poem_text && parser(markdownToHtml(pageContext.poem_text));
  const opinionText =
    pageContext.sc_opinion && parser(markdownToHtml(pageContext.sc_opinion));
  const poemImage = getImage(pageContext.artwork);

  const fadePageVariants = useMemo(
    () =>
      ({
        showContent: {
          opacity: 0,
          transition: {
            duration: 0.5,
            ease: 'linear',
          },
        },
        hideContent: {
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: 'linear',
          },
        },
      } as Variants),
    [],
  );

  const footerMotionProps = useMemo(
    () =>
      ({
        initial: {
          opacity: 0,
        },
        animate: {
          opacity: 1,
        },
        exit: {
          opacity: 0,
        },
        transition: {
          duration: 0.5,
          ease: 'easeOut',
        },
      } as MotionProps),
    [],
  );

  const handleScrollUp = () => {
    scrollTo(0, 0);
  };

  const description =
    pageContext.og_description || pageContext.sc_opinion.split('\n')?.[0];

  return (
    // Fragment keeps sticky elements at top level of scroll view (no Layout wrapper)
    <>
      <SEO
        title={pageContext.case_name}
        description={description}
        path={path}
        permalink={pageContext.permalink}
      />

      {/* Main Header Nav */}
      <Header
        isPoemPage
        isPoemPageScrolled={isHeaderInViewReady && !isHeaderInView}
        onMobileOpen={setMobileOpen}
        mobileOpen={mobileOpen}
        audio={pageContext.audio_file}
        readBy={pageContext.read_by}
        caseName={pageContext.case_name}
        caseSubtitle={pageContext.case_subtitle}
        customCss={
          tw`fixed` // fixed prevents flutter on the inview boundary
        }
      />

      {
        // Defer main content until after mount
        // (added to avoid problems from heavy use of react-device-detect)
        !hasMounted ? (
          // invisible SSG text for SEO - textual content of the poem info sections
          <div tw="p-20 w-screen h-screen text-white bg-white">
            {pageContext.facts.map((fact) => (
              <p key={`seo-${fact.body.slice(0, 20)}`}>{fact.body}</p>
            ))}
            {poemText}
            {opinionText}
          </div>
        ) : (
          <>
            <div tw="relative w-full">
              <PoemPageTopHalf
                isEntered={isEntered}
                pageContext={pageContext}
                onHeaderInViewChange={handleHeaderInViewChange}
              />

              <PoemInfo
                customCss={tw`bg-[#D18039]`}
                showFooterDiv={
                  // hidden div at bottom hooked to an inview that shows the footer
                  <div
                    ref={lastElementRef}
                    css={[
                      tw`z-50 absolute left-1/3 w-20 h-10 /* bg-blue-700 // debug */`,
                      isMobile /* includes iPad */
                        ? tw`[bottom: 10vh]`
                        : tw`[bottom: 40vh]`,
                    ]}
                  />
                }
              >
                {/* Context Page */}
                <PoemInfoPage
                  title="Context"
                  customCss={tw`bg-brand-coral`}
                  poem={pageContext}
                >
                  {pageContext.facts.map((fact) => (
                    <p key={fact.body.slice(0, 20)} tw="mb-4">
                      {fact.body}
                    </p>
                  ))}
                </PoemInfoPage>

                {/* Poem page */}
                <PoemInfoPage
                  title="Poem"
                  customCss={tw`bg-brand-red-delicious`}
                  poem={pageContext}
                >
                  {poemText && (
                    <div tw="leading-loose [transform: translateY(-4px) /* compensates top for leading */]">
                      {poemText}
                    </div>
                  )}

                  {poemImage && (
                    <button
                      css={blackButtonCss}
                      onClick={() => setModalOpen(true)}
                    >
                      Artist's Work <RightCaret />
                    </button>
                  )}
                </PoemInfoPage>

                {/* Scotus Page */}
                <PoemInfoPage
                  title="Scotus"
                  titleLine2="Opinion"
                  customCss={tw`bg-[#D18039]`}
                  poem={pageContext}
                >
                  {opinionText}

                  <LinkPlus
                    customCss={blackButtonCss}
                    to={pageContext.sc_opinion_url}
                  >
                    View the Case <RightCaret />
                  </LinkPlus>
                  <br />
                </PoemInfoPage>
              </PoemInfo>

              <AnimatePresence>
                {/* show trigger is above in PoemInfo showFooterDiv prop */}
                {showFooter && (
                  <PoemFooter {...footerMotionProps}>
                    <button
                      onClick={handleScrollUp}
                      tw="font-bold underline absolute bottom-20"
                    >
                      <UpCaret />
                      Back to top
                    </button>
                    <FooterNav>
                      {navData.map((item, index) => (
                        <li key={item.to}>
                          <LinkPlus
                            to={item.to}
                            onClick={getPageExitClick(item.to)}
                            activeClassName="active"
                          >
                            {item.text}
                            {index !== navData.length - 1 && (
                              <span tw="pl-2 text-black">|</span>
                            )}
                          </LinkPlus>
                        </li>
                      ))}
                    </FooterNav>
                  </PoemFooter>
                )}
              </AnimatePresence>
            </div>
            <AnimatePresence>
              {(!isEntered || isExitingPage) && (
                <motion.div
                  initial={isExitingPage ? 'showContent' : 'hideContent'}
                  exit="showContent"
                  animate="hideContent"
                  variants={fadePageVariants}
                  onAnimationComplete={() => setIsEntered(true)}
                  tw="z-30 pointer-events-none bg-white fixed inset-x-0 top-0 h-screen"
                />
              )}
            </AnimatePresence>
          </>
        )
      }

      {/* Original artwork modal */}
      <PoemModal
        {...pageContext}
        poemImage={poemImage}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </>
  );
};

const blackButtonCss = tw`pointer-events-auto relative bg-black text-white text-base [letter-spacing: 0.1rem] hover:(invert) focus-visible:(invert)
px-4 py-2 my-8 inline-flex items-center rounded-none [transition: all 0.2s linear]`;

export const PoemFooter = tw(motion.div)`
  z-10 fixed inset-x-0 bottom-4 flex justify-center px-2 xl:pb-0 
  text-white font-normal capitalize text-sm md:(text-base)`;

export const FooterNav = tw.ul`flex flex-wrap gap-2 justify-center  md:flex-nowrap`;

const RightCaret = () => (
  <div tw="pl-2">
    <svg
      width="11"
      height="19"
      viewBox="0 0 11 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.660156 0.723145L10.1376 9.80147"
        stroke="white"
        strokeWidth="1.83009"
      />
      <line
        x1="9.97328"
        y1="8.7114"
        x2="0.85184"
        y2="18.3522"
        stroke="white"
        strokeWidth="1.83009"
      />
    </svg>
  </div>
);

const UpCaret = () => (
  <span tw="rotate-180 flex justify-center">
    <DownCaret />
  </span>
);

const DownCaret = () => (
  <svg
    width="42"
    height="25"
    viewBox="0 0 42 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M40.1191 2.22705L20.2767 22.9419" stroke="white" strokeWidth="4" />
    <line
      x1="22.6596"
      y1="22.5824"
      x2="1.58781"
      y2="2.6458"
      stroke="white"
      strokeWidth="4"
    />
  </svg>
);

export default Page;
