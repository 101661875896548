import { motion, useMotionValue, Variants } from 'framer-motion';
import React, {
  Children,
  cloneElement,
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import InView from 'react-intersection-observer';
import { useResize } from '~/hooks';
import { getPadScroll, PageMetrics, PoemInfoPageProps } from './PoemInfoPage';
import { CustomCssProps } from '~/config';

// `isMobile/Only`: the rest of this project should use `isMobileOnly`. This component
// uses `isMobile` because it features scrolljacking, which doesn't work well on touch.

type PoemInfoProps = CustomCssProps & {
  children: ReactNode;
  /** in-view watcher for showing the footer, which needs to be here but not via `children` */
  showFooterDiv: ReactElement<HTMLDivElement>;
  motionVariants?: Variants;
};

export const PoemInfo: FC<PoemInfoProps> = ({
  children,
  showFooterDiv,
  motionVariants = {},
  customCss,
}) => {
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [metrics, setMetrics] = useState<PageMetrics[]>([]);
  const totalHeight = useMotionValue(0);

  const childCount = useMemo(() => Children.count(children), [children]);

  const handleResize = useCallback(() => {
    if (!isMobile /* see note at top */ && pageRefs.current.every(Boolean)) {
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      let top = 0;
      let total = 0;
      let newMetrics: PageMetrics[] = new Array(childCount);
      pageRefs.current.forEach((el, i) => {
        const height = el!.clientHeight;
        total += height + getPadScroll(i, false); // before
        newMetrics[i] = {
          viewportWidth,
          viewportHeight,
          topInViewport: top,
          snapTop: getPadScroll(i, true) + top - getPadScroll(i, false),
          height,
        };
        top += height; // after
      });
      setMetrics(newMetrics);
      totalHeight.set(total);
    }
  }, [childCount, totalHeight]);
  useResize(handleResize, 500);

  const clonedChildren = useMemo(() => {
    if (!metrics || childCount === 0) {
      return null;
    }
    return Children.map(
      children as ReactElement<PoemInfoPageProps>[],
      (child, i) => {
        return cloneElement(child, {
          key: child.props.title,
          ref: (el: HTMLDivElement | null) => (pageRefs.current[i] = el),
          pageIndex: i,
          pages: childCount,
          allPageMetrics: metrics,
        } as Partial<PoemInfoPageProps>);
      },
    );
  }, [childCount, children, metrics]);

  return (
    <InView
      onChange={
        handleResize /* Fixes bug where first page wouldn't scroll all the way to bottom, probably didn't get its height on first try */
      }
    >
      <motion.div
        id="poem-info"
        tw="relative flex flex-col justify-start"
        css={customCss}
        style={{
          height: isMobile /* see note at top */ ? 'auto' : totalHeight,
        }}
        {...motionVariants}
      >
        {clonedChildren}
      </motion.div>
      {showFooterDiv}
    </InView>
  );
};
