import React from 'react';

export const TwitterIcon = ({
  color = 'white',
  stroke = '',
  width = '22',
  height = '17',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.995 2.68797C19.8914 2.15206 20.5622 1.30821 20.882 0.313973C20.0397 0.813794 19.1181 1.16587 18.157 1.35497C17.5088 0.669323 16.6532 0.215892 15.7219 0.064575C14.7905 -0.0867419 13.8353 0.072456 13.0034 0.517629C12.1715 0.962802 11.5091 1.66923 11.1184 2.52803C10.7276 3.38683 10.6301 4.35032 10.841 5.26997C9.1349 5.18432 7.46586 4.74085 5.94223 3.96836C4.41861 3.19586 3.07445 2.11161 1.99702 0.785973C1.44908 1.73108 1.28141 2.8493 1.52806 3.91355C1.77472 4.97779 2.41719 5.90826 3.32502 6.51597C2.64373 6.49407 1.97754 6.30961 1.38202 5.97797V6.03197C1.38221 7.02294 1.72515 7.98335 2.35268 8.75031C2.98021 9.51727 3.8537 10.0436 4.82502 10.24C4.19308 10.4119 3.53022 10.4372 2.88702 10.314C3.16175 11.1663 3.69572 11.9116 4.41442 12.4458C5.13312 12.98 6.00068 13.2766 6.89602 13.294C5.37585 14.4871 3.49849 15.1341 1.56602 15.131C1.22302 15.131 0.881016 15.111 0.541016 15.072C2.50343 16.333 4.78739 17.0023 7.12002 17C8.72662 17.011 10.3194 16.7027 11.8058 16.0929C13.2923 15.4832 14.6427 14.5841 15.7787 13.448C16.9148 12.3119 17.8137 10.9614 18.4233 9.47487C19.033 7.98838 19.3412 6.39558 19.33 4.78897C19.33 4.60297 19.326 4.41797 19.317 4.23397C20.1575 3.62659 20.8828 2.87414 21.459 2.01197C20.676 2.35902 19.8455 2.58688 18.995 2.68797V2.68797Z"
      fill={color}
      className="fg-path"
    />
  </svg>
);
