import React from 'react';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import { FC, useEffect, useMemo, useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import {
  CopyIcon,
  FacebookIcon,
  ShareIcon,
  TwitterIcon,
} from '~/components/common/icons';
import { CustomCssProps, Poem } from '~/config';
import { isMobile } from 'react-device-detect';

type ShareMenuProps = CustomCssProps & {
  poem: Poem;
};

/**
 * Animated ShareMenu for sharing metadata to social media links.
 * Passes currently-viewed Poem's info into query string links for various socials
 */
export const ShareMenu: FC<ShareMenuProps> = ({ poem, customCss }) => {
  const [openShare, setOpenShare] = useState(false);
  const [copied, setCopied] = useState(false);

  const shareURLs = useMemo(
    () => ({
      twitter:
        poem.tweet &&
        `https://twitter.com/intent/tweet?${new URLSearchParams({
          url: poem.permalink,
          text: poem.tweet,
        }).toString()}`,
      facebook:
        poem.fb_post &&
        `https://www.facebook.com/sharer/sharer.php?${new URLSearchParams({
          u: poem.permalink,
          href: poem.permalink,
          quote: `${poem.fb_post} ${poem.fb_hashtag}`,
        })}`,
    }),
    [poem],
  );

  const socialLinks = [
    {
      name: 'Twitter',
      link: shareURLs.twitter,
      icon: <TwitterIcon height={'1rem'} width={'1rem'} color={'#fff'} />,
    },

    {
      name: 'Facebook',
      link: shareURLs.facebook,
      icon: <FacebookIcon height={'1rem'} width={'1rem'} color={'#fff'} />,
    },
    {
      name: 'CopyToClipboard',
      link: poem.permalink,
      icon: <CopyIcon height={'1rem'} width={'1rem'} color={'#fff'} />,
    },
  ];

  // Close menu after copying url
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenShare(false);
      setCopied(false);
    }, 1100);

    if (copied) {
      timer;
    }
    return () => {
      clearTimeout(timer);
    };
  }, [copied]);

  // Toggle menu
  const handleOpen = () => {
    setOpenShare(!openShare);
  };

  // Copy URL to clipboard and close menu after delay
  const handleCopy = async () => {
    if (navigator) {
      try {
        await navigator.clipboard.writeText(poem.permalink);
        setCopied(true);
      } catch (e) {
        console.error('Failed to copy to the clipboard.', e);
        alert('There was an error copying to the clipboard.');
      }
    }
  };

  // Variants receive 'custom' prop as 'i' for staggered delay
  const itemVariants = useMemo<Variants>(
    () => ({
      hidden: (i) => ({
        opacity: 0,
        y: '100%',
        transition: {
          duration: 0.3,
          ease: 'easeInOut',
          delay: i * 0.1,
        },
      }),
      visible: (i) => ({
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.3,
          ease: 'easeInOut',
          delay: i * 0.1,
        },
      }),
    }),
    [],
  );

  return (
    <ShareContainer css={customCss}>
      <ShareButton onClick={handleOpen} title="Share this poem">
        <ShareIcon tw="w-4 h-4" />
      </ShareButton>
      <AnimatePresence>
        {openShare && (
          <>
            {socialLinks.map((link, idx) => {
              if (link.name === 'CopyToClipboard') {
                return (
                  <CopyButton
                    variants={itemVariants}
                    key={link.name}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    custom={1 + idx}
                    title="Copy to clipboard"
                    onClick={handleCopy}
                    copied={copied}
                  >
                    {link.icon}
                  </CopyButton>
                );
              }
              return (
                <ShareLink
                  variants={itemVariants}
                  key={link.name}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  custom={1 + idx}
                  href={link.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={`Share on ${link.name}`}
                >
                  {link.icon}
                </ShareLink>
              );
            })}
          </>
        )}
      </AnimatePresence>
    </ShareContainer>
  );
};

const ShareContainer = styled(motion.div)(() => [
  /* LOCKUP - poem title, share button, and prev/next arrows with even space on either side of title -- use same breakpoints as Title1 */
  tw`absolute flex gap-2 justify-center my-10 mb-0 flex-col -left-[9.25rem] lg:(-left-[12.25rem]) 2xl:(-left-[13.75rem])`,
  /* Not isMobileOnly -- see PoemInfoPage -- same breakpoints as Title1 */
  isMobile && tw`my-0 -left-[9.25rem]`,
]);
const shareStyles = [
  tw`flex justify-center items-center h-12 w-12 m-0 p-4 rounded-full bg-black [transition:background .25s ease-in-out] hover:(bg-black bg-opacity-70)`,
];
const ShareButton = styled(motion.button)(() => [tw`[z-index:9]`, shareStyles]);
const ShareLink = styled(motion.a)(() => [tw`[z-index:8]`, shareStyles]);
const CopyButton = styled(motion.button)(({ copied }: { copied: boolean }) => [
  tw`[z-index:8] before:(opacity-0 bg-black text-white)`,
  copied &&
    css`
      :before {
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
        content: 'Copied!';
        font-size: 12px;
        position: absolute;
        right: -52px;
        display: block;
      }
    `,
  shareStyles,
]);
