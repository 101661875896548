import {
  ForwardRefComponent,
  motion,
  MotionStyle,
  useMotionTemplate,
  useSpring,
  useViewportScroll,
  Variants,
} from 'framer-motion';
import React, {
  FC,
  forwardRef,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import tw, { styled } from 'twin.macro';
import { ShareMenu } from '~/components/common/social/ShareMenu';
import { CustomCssProps, isSSG, Poem as PoemProps } from '~/config';
import { useResize } from '~/hooks';

// `isMobile/Only`: the rest of this project should use `isMobileOnly`. This component
// uses `isMobile` because it features scrolljacking, which doesn't work well on touch.

export type PageMetrics = {
  viewportWidth: number;
  viewportHeight: number;
  topInViewport: number;
  snapTop: number;
  height: number;
};

const DEBUG = false;
export const TOP = { mobile: 266, desk: 356 };

// this is used in scroll calcs here, but also in PoemInfo during height tally
export const getPadScroll = (pageIndex: number, additive: boolean) => {
  // first page needs some extra pad so the text doesn't scroll out of view as you enter.
  // we don't want a dead spot on enter, but enough pad on scroll up.
  const firstPagePad = 800;
  if (pageIndex === 0) {
    return firstPagePad;
  }

  // subsequent pages: so scroll doesn't start too quickly, and page size doesn't reduce too much on xl
  // (`additive` used by scroll engine to get effective position of individual element)
  const pad = 1000;
  return additive ? firstPagePad + pad * pageIndex : pad;
};

export type PoemInfoPageProps = CustomCssProps & {
  /* For use at top level */
  title: string;
  /* For use at top level */
  titleLine2?: string;
  /* For use at top level */
  children: ReactNode;
  /* For share buttons */
  poem: PoemProps;

  /* injected */
  pageIndex?: number;
  /* injected */
  pages?: number;
  /* injected */
  allPageMetrics?: PageMetrics[];
};

const springConfig = {
  damping: 20,
  stiffness: 80,
};

export const PoemInfoPage: ForwardRefComponent<
  HTMLDivElement,
  PoemInfoPageProps
> = forwardRef(
  (
    {
      pageIndex = 0,
      pages = 0,
      allPageMetrics,
      title,
      titleLine2,
      poem,
      children,
      customCss,
    },
    ref,
  ) => {
    const metrics = allPageMetrics?.[pageIndex];
    const frameTop = isMobileOnly ? TOP.mobile : TOP.desk;
    const containerRef = useRef<HTMLDivElement | null>(null);
    useImperativeHandle(ref, () => containerRef.current!); // one way to share the forwarded ref

    const canShowIntro =
      !isMobile /* see note above on isMobile/Only */ && pageIndex === 0;
    const [introDelayActive, setIsIntroActive] = useState(canShowIntro);

    const { scrollY } = useViewportScroll();
    const offsetY = useSpring(frameTop, springConfig);
    const maskBottom = useSpring(0, springConfig);
    const clipPath = useMotionTemplate`polygon(
      0px   0px,
      100%  0px,
      100%  ${maskBottom}px,
      0px   ${maskBottom}px
    )`;

    const handleScroll = useCallback(() => {
      if (!metrics || !containerRef.current) {
        return;
      }
      const container = containerRef.current.parentElement!;
      const containerTop = container.getBoundingClientRect().top; // (-) MAIN RAW SCROLL VALUE

      // "frame" refers to the viewport area, not the full page height
      const frameHeight = metrics.viewportHeight! - frameTop; // (+)
      const selfHeight = metrics.height!;
      const scrollableHeight = selfHeight - frameHeight;

      const layoutY = metrics.topInViewport!; // the page's would-be top in the container, if position were relative

      const normalizedScrollRaw = -(containerTop - frameTop + layoutY); // 0 - selfHeight, with 0 at frame top
      const adjust = getPadScroll(pageIndex, true); // want to start immediately on first slide, and late on others to buffer
      const normalizedScroll = normalizedScrollRaw - adjust;

      // INTRO.
      if (canShowIntro) {
        const padded = frameTop + frameHeight * 0.1;
        if (introDelayActive) {
          if (containerTop < padded) {
            setIsIntroActive(false);
          }
        } else if (!introDelayActive && containerTop >= padded) {
          setIsIntroActive(true);
        }
      }

      // SCROLL.
      if (
        normalizedScroll >= 0 /* start of scroll range */ &&
        normalizedScroll <= scrollableHeight /* end of scroll range */
      ) {
        offsetY.set(-normalizedScroll + frameTop);
      } else if (normalizedScroll < 0) {
        offsetY.set(frameTop);
      } else if (normalizedScroll > scrollableHeight) {
        offsetY.set(-selfHeight + frameTop + frameHeight);
      }

      // MASK. Except on last page, unmask upward during scroll down / mask downward during scroll up
      if (
        pageIndex < pages - 1 &&
        normalizedScroll >= scrollableHeight &&
        normalizedScroll <= selfHeight
      ) {
        maskBottom.set(selfHeight - (normalizedScroll - scrollableHeight));
      } else if (normalizedScroll > scrollableHeight) {
        maskBottom.set(scrollableHeight);
      } else if (pageIndex === pages - 1 || normalizedScroll < selfHeight) {
        maskBottom.set(selfHeight);
      }
    }, [
      canShowIntro,
      frameTop,
      introDelayActive,
      maskBottom,
      metrics,
      offsetY,
      pageIndex,
      pages,
    ]);

    useEffect(() => {
      if (!isMobile) {
        const cleanup = scrollY.onChange(handleScroll);
        return cleanup;
      }
    }, [handleScroll, scrollY]);

    // -- Title / Content positioning --
    const titleRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const handleResize = useCallback(() => {
      handleScroll();

      if (isMobile && titleRef.current && contentRef.current) {
        // Pulls content up next to the sticky title
        // accommodates a css trick to make sticky work right on mobile, see Title1
        let titleHeight = titleRef.current.getBoundingClientRect().height;
        if (isMobileOnly) {
          titleHeight +=
            !isSSG && window.innerHeight < window.innerWidth ? 68 : 98;
        } else if (pageIndex === 2) {
          titleHeight += 56;
        } else {
          titleHeight += 40;
        }
        contentRef.current.style.marginTop = `-${titleHeight}px`;
      }
    }, [handleScroll, pageIndex]);
    useResize(handleResize, 0.5);

    const { titleVariants, contentVariants } = useMemo(
      () =>
        canShowIntro
          ? {
              titleVariants: {
                in: {
                  translateY: 0,
                  opacity: 1,
                  transition: {
                    duration: 1.25,
                    ease: 'circOut',
                  },
                },
                out: {
                  translateY: 300,
                  opacity: 0,
                  transition: {
                    duration: 1,
                    ease: 'linear',
                  },
                },
              } as Variants,
              contentVariants: {
                in: {
                  translateY: 0,
                  opacity: 1,
                  transition: {
                    duration: 1,
                    delay: 0.2,
                    ease: 'circOut',
                  },
                },
                out: {
                  translateY: 300,
                  opacity: 0,
                  transition: {
                    duration: 1.1,
                    ease: 'linear',
                  },
                },
              } as Variants,
            }
          : {},
      [canShowIntro],
    );

    const style: MotionStyle = isMobile /* see note at top */
      ? { position: 'relative', top: 0 }
      : {
          position: 'fixed',
          clipPath: pageIndex < pages - 1 ? clipPath : undefined,
          top: offsetY,
        };

    return (
      <Container
        ref={containerRef}
        pageIndex={pageIndex}
        css={customCss}
        style={style}
      >
        <Column>
          <TitleContainer
            ref={titleRef}
            variants={titleVariants}
            initial="out"
            animate={introDelayActive ? 'out' : 'in'}
          >
            {!isMobileOnly && <ShareMenu poem={poem} />}

            <Title1>
              {title}

              {/* Dots */}
              {new Array(pages).fill(null).map((_, i) => (
                <Dot
                  key={`dot-${i}`}
                  isSelected={i === pageIndex}
                  metrics={allPageMetrics?.[i]}
                  containerRef={containerRef}
                />
              ))}
            </Title1>
            {titleLine2 && <Title2>{titleLine2}</Title2>}
          </TitleContainer>
          <ContentContainer
            ref={contentRef}
            variants={contentVariants}
            initial="out"
            animate={introDelayActive ? 'out' : 'in'}
          >
            <br />
            {!isMobile && <br />}
            {children}

            {/* DEBUG -- make it tall for scroll testing */}
            {DEBUG && <>x2 {children}</>}
            {DEBUG && <>x3 {children}</>}

            {/* this works better than pb with short content, so sticky titles overlap.
                it also seems important for making sure the full text is shown and padding the next scroll. */}
            {new Array(pageIndex === pages - 1 ? 5 : 8)
              .fill(null)
              .map((_, i) => (
                <br key={`pad${i}`} />
              ))}
          </ContentContainer>
        </Column>
      </Container>
    );
  },
);
PoemInfoPage.displayName = 'PoemInfoPage';

const Container = styled(motion.section)<Partial<PoemInfoPageProps>>(
  ({ pageIndex = 0 }) => [
    tw`relative left-0 w-full /* works for relative during intro */ bg-black text-white`,
    {
      zIndex: 9 - pageIndex,
      minHeight: `calc(100vh - ${isMobileOnly ? TOP.mobile : TOP.desk}px)`,
    },
    tw`pb-4`, // so dots don't hit bottom on scroll (in general can't pad this container, affects calcs)
    DEBUG && {
      // temp
      left: ((2 - pageIndex) * window.innerWidth) / 4,
      opacity: 0.9,
    },
  ],
);

const Column = styled(motion.div)(() => [
  tw`relative max-w-3xl /* max-w-4xl <- design size, I reduced width for more scrolling action... */
    mx-auto pl-24 pr-8 sm:(pl-36 pr-10) md:(pl-40 pr-10) lg:(pl-32) 2xl:(pl-40)`,
  isMobile && tw`md:(pl-40 pr-10)`,
]);

const TitleContainer = styled(motion.div)(() => [
  tw`top-96 sticky landscape:(top-0) // mobile (phone & tablet) use sticky position. text is offset upward in handleResize above.
    lg:(fixed landscape:(top-96))`, // desktop soft-scroll uses fixed because on Safari (or maybe Mac in general), `sticky` jitters noticeably during scroll
]);

// On mobile, content top is offset for sticky in handleResize above
const ContentContainer = tw(motion.div)`
  font-light text-lg 2xl:(text-xl leading-snug)
`;

const Title1 = styled.h2<{}>(({}) => [
  tw`relative flex flex-row-reverse items-center gap-2 // gap to dots
    [writing-mode: vertical-lr] // using this mode varying text lengths don't mess up positioning
    rotate-180 origin-center
    uppercase font-bold [letter-spacing: 0.2rem]
    /* LOCKUP - poem title, share button, and prev/next arrows with even space on either side of title */
    top-5 my-4 -left-[5.75rem] [font-size: 3rem] lg:(-left-32 [font-size: 4rem]) 2xl:(gap-3 -left-40 text-4xl)
    `,
  isMobile && // not isMobileOnly, see note at top. Here base is small phone, sm is phone, md is iPad
    tw`text-[3rem] -top-12 -left-24 sm:(text-3xl -left-32 -top-5) md:(-top-0.5 -left-[7.25rem] [font-size: 4rem])
      mt-40 // special adjustment to get sticky position right, factored into handleResize above`,
]);

// TODO this should really be a span of the main H2 not a separate one
const Title2 = styled.h2(() => [
  tw`absolute
  [writing-mode: vertical-lr] // using this mode varying text lengths don't mess up positioning
  uppercase font-bold
  /* Match breakpoints to Title1 */
  top-7 [font-size: 1.5rem] [letter-spacing: 0.1rem] -left-[2.85rem] ml-1 lg:(-left-[4.3rem] [font-size: 2rem] [letter-spacing: 0.2rem]) 2xl:(-left-[4.6rem] [font-size: 2.75rem])
  rotate-180 origin-center`,
  isMobile && // not isMobileOnly, see note at top. Here base is small phone, sm is phone, md is iPad
    tw`-top-[3.3rem] [font-size: 1.8rem] -left-[3.3rem] sm:(-top-[1.6rem] -left-[3.7rem] [font-size: 2.25rem]) 
       md:(-top-1.5 -left-[3.6rem])`,
]);

const Dot: FC<{
  isSelected: boolean;
  metrics?: PageMetrics;
  containerRef: RefObject<HTMLDivElement>;
}> = ({ isSelected, metrics, containerRef }) => (
  <button
    css={[
      isSelected ? tw`bg-white` : tw`bg-black cursor-pointer`,
      tw`rounded-full w-3 h-3 mr-1 2xl:(w-[18px] h-[18px])`,
    ]}
    onClick={
      // we can't calc the scroll positions accurately due to the use of `sticky`, so dots are only clickable on desktop
      isMobile || isSelected || isSSG || !containerRef.current || !metrics
        ? undefined
        : () =>
            document.scrollingElement?.scrollTo(
              0,
              containerRef.current!.parentElement!.offsetTop + metrics.snapTop,
            )
    }
  />
);
